@import "~bootstrap/dist/css/bootstrap.min.css";

/* ICONS */
@import "~material-design-icons/iconfont/material-icons.css";
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css');
// @import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

@import "~react-toastify/dist/ReactToastify.min.css";

/* FONTS */
@import 'fonts/hk-grotesk';


/* ABSTRACTS */
@import 'abstracts/variables';
@import 'abstracts/mixins';


/* BASE */
@import 'base/typography';
@import 'base/colors';
@import 'base/reset';


/* LAYOUT */
@import 'layout/header';
@import 'layout/menu-left';


/* COMPONENTS */
@import 'components/button';
@import 'components/card';
@import 'components/form';
@import 'components/sticky-page-header';
@import 'components/input';
@import 'components/date-range-picker';
@import 'components/table';


/* PAGES */
@import 'pages/sign-in';
@import 'pages/page-not-found';

@import 'pages/dashboard/dashboard';

.MuiTable-root {
    th {
        background-color: #EAEDF2;
        font-size: 14px;
        font-family: $font-family;
        font-weight: bold;
        line-height: 17px;

        .MuiButton-root {
            font-weight: bold;
        }
    }

    td {
        font-family: $font-family;
        font-size: 14px;
        line-height: 17px;
    }
}

.Toastify__toast-body {
    color: #fff;
}

.dropdown-toggle {
    z-index: 0 !important;
}

.control-label {
    display: inline-block;
    float: left;
    margin-right: 3%;
    text-align: right;
    width: 22%;
    clear: both;
}

.invalid-feedback {
    padding-left: 25%;
    clear: both;
}

form {
    width: 100%;
}

.form-input {
    width: 75%;
    float: right;
    margin-bottom: 5px;

    &.no-border {
        padding: 0;
        border: none;
    }

    &.has-margin {
        margin-bottom: 30px;
    }
}

.form-check {
    label {
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media (min-width: 992px) {
    .modal-dialog {
        margin: 4.75rem auto;
    }
}

.table {
    thead {
        th {
            background-color: $color-primary;
            color: #fff;
            border-bottom: none;
        }
    }

    tbody {
        td {
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.theme-input-color {
    color: $color-second;
}

.form-input-group {
    width: 60%;
    float: left;
}

.form-input-btn {
    float: left;
    width: 12%;
    margin-left: 3% !important;
}

.Toastify__toast-container {
    span {
        color: #fff;
    }
}

div[role=tooltip] {
    z-index: 999 !important;
}

.overlay {
    // height: 0px;
    overflow: visible;
    pointer-events: none;
    background: none !important;
}

.modal {
    z-index: 1300;
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}

.text-underline {
    text-decoration: underline;
}

.bg-info {
    background-color: $color-primary !important;
}

.icon-danger path {
    fill: $color-danger;
}

/* Mobile */
@media (max-width: 576px) {
    .custom-sticky-page-header .breadcrumb-container {
        padding-top: 5px;
    }

    .list-search-box {
        .control-label {
            text-align: left;
            width: 100%;
        }

        .form-input,
        .form-input-group {
            width: 100%;
        }

        .form-input.has-margin {
            margin-bottom: 0.5rem;
        }

        .form-input-btn {
            margin-left: 0 !important;
            width: 100%;
        }
    }

    .control-label {
        text-align: left;
        width: 100%;
    }

    .form-input,
    .form-input-group {
        width: 100%;
    }

    .header-profile button span {
        padding-left: 5px;
        padding-right: 5px;
        word-wrap: normal;
        max-width: 215px;
        text-align: left;}
}

@media (max-width: 959.95px) {
    td.hide-mobile {
        display: none;
    }

    input.max-mobile {
        padding-left: 2px;
        padding-right: 2px;
    }
}

.img-thumbnail-container {
    aspect-ratio: 1 / 1;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    margin: .25rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    .img-thumbnail {
        border: 0;
        display: none;
        height: auto;
        width: 100%;
    }

    .btn-delete {
        position: absolute;
        right: -10px;
        top: -8px;
        border: 1px solid #e8617c;
        border-radius: 5px;
        padding: 3px;
    }
}

button svg {
    max-width: 20px;
    max-height: 20px;
}
.card {
	border: none !important;
	border-radius: 3px !important;
	padding: 15px;
}

.card-title {
	padding: 0 1.25rem;
    padding-bottom: 0;
    margin-bottom: 0;

	h4 {
		border-bottom: thin solid #d5d5d5;
		font-size: 16px;
		line-height: 19px;
		font-weight: 700;
		color: #101820;
		padding-bottom: 1.25rem;
	}
}
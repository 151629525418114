.btn-theme {
    color: #fff;
    outline: 0 none;

    .MuiButton-label {
        color: #fff;
        text-transform: none;
        font-size: 14px;
    }
}

.btn-theme-1 {
    background-color: $color-second !important;

    .MuiButton-label {
        text-transform: none;
        font-size: 14px;
    }
}

.btn-theme-2 {
    background-color: #51ADCF !important;

    .MuiButton-label {
        color: #fff;
        text-transform: none;
        font-size: 14px;
    }
}

.btn-icon {
    &:not(.simple) .MuiButton-label {
        svg {
            margin-right: 5px;

            .a {
                fill: #fff;
                stroke: #fff;
            }
        }
    }

    &.simple {
        background: transparent;
        box-shadow: none;
        outline: 0 none;

        &:hover,
        &:focus,
        &:active {
            background: transparent;
            box-shadow: none;
        }
    }
}

.custom-button-component {
    button {
        border-radius: 4px;
        background-color: $color-default;
        height: 43px;
        box-shadow: unset;

        .MuiButton-label {
            text-transform: none;
            color: $color-secondary;
            font-size: 14px;
            font-family: $font-family-regular;

            span,
            div,
            i,
            svg {
                text-transform: none;
                color: $color-secondary;
                font-size: 14px;
                font-family: $font-family-regular;
            }

            svg {
                fill: rgba($color-secondary, 0.7);

                rect,
                g,
                path {
                    fill: rgba($color-secondary, 0.7);
                }
            }

            &:hover {
                color: rgba($color-secondary, 0.8);

                span,
                div,
                i,
                svg {
                    color: rgba($color-secondary, 0.8);
                }
            }
        }

        &:hover {
            box-shadow: unset;
        }

        &.MuiButton-containedPrimary,
        &.MuiButton-textPrimary {
            background-color: $color-active;

            &:hover {
                background-color: rgba($color-active, 0.8);
            }

            .MuiButton-label {
                color: white;

                span,
                div,
                i,
                svg {
                    color: white;
                }

                svg {
                    fill: white;

                    rect,
                    g,
                    path {
                        fill: white;
                    }
                }
            }
        }

        &.MuiButton-containedSecondary,
        &.MuiButton-textSecondary {
            background-color: $color-second;

            &:hover {
                background-color: rgba($color-second, 0.8);
            }

            .MuiButton-label {
                color: white;

                span,
                div,
                i,
                svg {
                    color: white;
                }

                svg {
                    fill: white;

                    rect,
                    g,
                    path {
                        fill: white;
                    }
                }
            }
        }

        &.MuiButton-sizeSmall {
            height: 35px;
        }

        &.MuiButton-sizeLarge {
            height: 50px;
        }

        &.btn {
            display: inline-block;
            font-weight: 400;
            color: #212529;
            text-align: center;
            vertical-align: middle;
            -webkit-user-select: none;
            user-select: none;
            background-color: transparent;
            border: 1px solid transparent;
            padding: .375rem .75rem;
            font-size: 1rem;
            line-height: 1.5;
            border-radius: .25rem;
            transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

            &.btn-outline-success {
                color: #28a745 !important;
                border-color: #28a745 !important;

                .MuiButton-label {
                    color: #28a745 !important;
                }
            }
        }
    }

    a {
        border-radius: 4px;
        background-color: unset;
        height: auto;
        box-shadow: unset;

        &.MuiButton-text {
            color: unset;

            .MuiButton-label {
                color: $color-secondary;
                text-decoration: underline;

                span,
                div,
                i,
                svg {
                    color: $color-secondary;
                }

                svg {
                    fill: $color-secondary;

                    rect,
                    g,
                    path {
                        fill: $color-secondary;
                    }
                }
            }

            &:hover {
                background-color: unset;

                .MuiButton-label {
                    color: rgba($color-secondary, 0.5);
                    background-color: unset;

                    span,
                    div,
                    i,
                    svg {
                        color: rgba($color-secondary, 0.5);
                    }

                    svg {
                        fill: rgba($color-secondary, 0.5);

                        rect,
                        g,
                        path {
                            fill: rgba($color-secondary, 0.5);
                        }
                    }
                }
            }
        }

        &.MuiButton-textPrimary {
            color: unset;

            .MuiButton-label {
                color: $color-active;
                text-decoration: underline;

                span,
                div,
                i,
                svg {
                    color: $color-active;
                }

                svg {
                    fill: $color-active;

                    rect,
                    g,
                    path {
                        fill: $color-active;
                    }
                }
            }

            &:hover {
                background-color: unset;

                .MuiButton-label {
                    color: rgba($color-active, 0.5);
                    background-color: unset;

                    span,
                    div,
                    i,
                    svg {
                        color: rgba($color-active, 0.5);
                    }

                    svg {
                        fill: rgba($color-active, 0.5);

                        rect,
                        g,
                        path {
                            fill: rgba($color-active, 0.5);
                        }
                    }
                }
            }
        }

        &.MuiButton-textSecondary {
            color: unset;

            .MuiButton-label {
                color: $color-second;
                text-decoration: underline;

                span,
                div,
                i,
                svg {
                    color: $color-second;
                }

                svg {
                    fill: $color-second;

                    rect,
                    g,
                    path {
                        fill: $color-second;
                    }
                }
            }

            &:hover {
                background-color: unset;

                .MuiButton-label {
                    color: rgba($color-second, 0.5);
                    background-color: unset;

                    span,
                    div,
                    i,
                    svg {
                        color: rgba($color-second, 0.5);
                    }

                    svg {
                        fill: rgba($color-second, 0.5);

                        rect,
                        g,
                        path {
                            fill: rgba($color-second, 0.5);
                        }
                    }
                }
            }
        }
    }

    .Mui-disabled {
        background-color: rgba($color-secondary, 0.3) !important;

        .MuiButton-label {
            color: $color-secondary !important;

            span,
            div,
            i,
            svg {
                color: $color-secondary !important;
            }

            svg {
                fill: rgba($color-secondary, 0.7) !important;

                rect,
                g,
                path {
                    fill: rgba($color-secondary, 0.7) !important;
                }
            }

            &:hover {
                color: rgba($color-secondary, 0.8) !important;

                span,
                div,
                i,
                svg {
                    color: rgba($color-secondary, 0.8) !important;
                }
            }
        }
    }
}

.btn-icon {
    border: none;
    background: transparent;
    outline: none;
    margin: 0 5px;
    padding: 0;
    line-height: 10px;

    &:hover {
        cursor: pointer;
    }
}
.custom-sticky-page-header {
    min-height: 98px;
    background-color: white;
    position: sticky;
    top: 56px;
    border-bottom: 1px solid #EAEDF2;
    z-index: 1000;

    .page-title {
		margin-left: 30px;
	    font-size: 20px;
	    line-height: 26px;
	    margin-top: 5px;
	    float: left;
	}

	.MuiButton-contained {
		float: right;
		margin-right: 15px;

		.MuiButton-label {
			font-size: 14px;
			text-transform: none;
			color: #fff;
		}
	}

	.breadcrumb-container {
		padding: 20px 30px;
		padding-bottom: 0;

		li {
			a, p {
				font-size: 12px;
				color: $color-dark;
				line-height: 25px;

				&.MuiTypography-colorTextPrimary {
					color: $color-primary;
				}
			}

			&.MuiBreadcrumbs-separator {
				margin-left: 4px;
				margin-right: 4px;
			}
		}
	}
}
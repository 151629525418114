.MuiTable-root{
    button {
        border: none;
        background: transparent;
        outline: none;
        margin: 0 5px;

        &:hover {
            cursor: pointer;
        }
    }

    a {
        margin: 5px 0;
    }

    thead {
        position: relative;
        z-index: 0;
    }
}

.fix-icon-color {
    .a {
        fill: #666;
        stroke: #666;
    }
}

.header-profile {
    button {
        img {}
        span {
            text-transform: capitalize;
            color: white;
            padding-left: 15px;
            padding-right: 15px;
        }
        svg {
            fill: white;

            rect, g, path {
                fill: white;
            }
        }
    }
}
.control-label {
	font-size: 14px;
	line-height: 22px;
	color: #101820;
}

.form-control:focus {   
	border-color: $color-primary;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba($color-primary, 0.6);
	outline: 0 none;
}

.form-check {
	padding-left: 0;

	input[type=checkbox], input[type=radio] {
		margin-right: 5px;
	}

	label {
		margin-right: 5px;
	}
}